import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  isBannerShown: false,
  isVerificationBannerShown: false,
};

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    showBanner: (state) => {
      return {
        ...state,
        isBannerShown: true,
      };
    },
    hideBanner: (state) => {
      return {
        ...state,
        isBannerShown: false,
      };
    },
    showVerificationBanner: (state) => {
      return {
        ...state,
        isVerificationBannerShown: true,
      };
    },
    hideVerificationBanner: (state) => {
      return {
        ...state,
        isVerificationBannerShown: false,
      };
    },
  },
});

export const { showBanner, hideBanner, showVerificationBanner, hideVerificationBanner } =
  bannerSlice.actions;
export default bannerSlice.reducer;
