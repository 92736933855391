import { createSlice } from '@reduxjs/toolkit';

export interface IConditionListProps {
  name: string;
  isVisited: boolean;
}

export interface IConditionItemsProps {
  ItemId: string;
  key: {
    value: string;
  };
}

export interface ISettingDetailsProps {
  path: string;
  parent: {
    parent: {
      name: string;
    };
  };
}

interface IMultiScreenerSliceProps {
  attemptStudies: string[];
  ConditionItems: IConditionItemsProps[];
  noOfAttempt: number;
  postDataFromScreener: any[];
  conditionListFromSurvey: IConditionListProps[];
  settingDetails: ISettingDetailsProps[];
  isLastQuestionSubmitted: boolean;
}

export const initialState: IMultiScreenerSliceProps = {
  attemptStudies: [],
  ConditionItems: [],
  noOfAttempt: 0,
  postDataFromScreener: [],
  conditionListFromSurvey: [],
  settingDetails: [],
  isLastQuestionSubmitted: false,
};

// type Payload = { [key: string]: string | null };
const multiScreenerSlice = createSlice({
  name: 'multiScreenerSlice',
  initialState,
  reducers: {
    setConditionItems: (state, action) => ({
      ...state,
      ConditionItems: action.payload,
    }),
    setNoOfAttemptToStore: (state, action) => ({
      ...state,
      noOfAttempt: action.payload,
    }),
    setPostDataFromScreener: (state, action) => ({
      ...state,
      postDataFromScreener: [...initialState.postDataFromScreener, ...action.payload],
    }),
    setConditionListFromSurvey: (state, action) => ({
      ...state,
      conditionListFromSurvey: [...initialState.conditionListFromSurvey, ...action.payload],
    }),
    setSettingDetails: (state, action) => ({
      ...state,
      settingDetails: [...initialState.settingDetails, ...action.payload],
    }),
    setAttemptStudies: (state, action) => ({
      ...state,
      attemptStudies: [...initialState.attemptStudies, ...action.payload],
    }),
    setLastQuestionSubmitted: (state, action) => ({
      ...state,
      isLastQuestionSubmitted: action.payload,
    }),
  },
});

export const {
  setConditionItems,
  setNoOfAttemptToStore,
  setPostDataFromScreener,
  setConditionListFromSurvey,
  setSettingDetails,
  setAttemptStudies,
  setLastQuestionSubmitted,
} = multiScreenerSlice.actions;
export default multiScreenerSlice.reducer;
