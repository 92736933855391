import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: any = {
  journeyType: '',
};

const cdpDataSlice = createSlice({
  name: 'cdpData',
  initialState,
  reducers: {
    storeCDPData: (_state, action: PayloadAction<any>) => ({
      ..._state,
      ...action.payload,
    }),
    resetCDPData: (_state) => ({
      journeyType: _state.payload,
    }),
    addJourneyType: (_state, action: PayloadAction<string>) => ({
      ..._state,
      journeyType: action.payload,
    }),
  },
});

export const { storeCDPData, addJourneyType, resetCDPData } = cdpDataSlice.actions;
export default cdpDataSlice.reducer;
