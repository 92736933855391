const getDomainName = (): string => {
  if (typeof window !== 'undefined') {
    const currentURL = window.location?.href;
    const url = new URL(currentURL);
    const hostname = url?.hostname;
    return hostname;
  }
  return '';
};
export default getDomainName;
