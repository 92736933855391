/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig } from 'axios';
import omit from 'lodash/omit';
import qs from 'qs';
import { CAROUSEL_VARIATION } from '../../constants';
import isEmpty from 'lodash/isEmpty';
import {
  COIItemProps,
  CommunityItemProps,
} from '../../components/RegistrationWorkflows/ConditionsOfInterest/ConditionsOfInterest.types';
import { fetchProfileDetailsForCR, verifyMyProfileEmail } from './axiosCDPFetcher';
import delay from 'lodash/delay';

export const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';

export const generateNewToken = async () => {
  const data = qs.stringify({
    client_id: process.env.CLIENT_ID,
    client_secret: process.env.CLIENT_SECRET,
    grant_type: process.env.GRANT_TYPE,
  });

  const config: AxiosRequestConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: process.env.AUTH_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getQuery = (
  query: any,
  retainQueryParamList: any = [],
  removeQueryParamList: any = []
): any => {
  // Default parameter to remove from URL
  const defaultParamsList = ['site', 'path'];

  if (removeQueryParamList.length > 0) {
    // Deleting query params from the query object
    for (const param of removeQueryParamList) {
      delete query[param];
    }
  }

  if (removeQueryParamList.length > 0) {
    // Adding extra field name to defaultParamsList except RetainQueryParamList
    Object.keys(query).forEach((param: string) => {
      if (!retainQueryParamList.includes(param)) {
        defaultParamsList.push(param);
      }
    });
  }

  return omit(query, defaultParamsList);
};

export const getQueryParams = (query: any) => {
  const newQuery = getQuery(query);
  const searchParams = new URLSearchParams();
  Object.keys(newQuery).forEach((key) => searchParams.append(key, newQuery[key]));
  return searchParams.toString();
};

export const getCarouselConfig = (
  variation: string,
  autoRotateDuration: string,
  playing: boolean
): any => {
  let carouselConfig = {};

  if (variation === CAROUSEL_VARIATION.VARIATION1) {
    carouselConfig = {
      indicators: false,
      duration: autoRotateDuration,
      navButtonsAlwaysVisible: true,
      navButtonsAlwaysInvisible: false,
      indicatorContainerProps: {
        style: {
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          // backgroundColor: 'transparent',
          zIndex: '1',
          top: '48%',
          alignItems: 'center',
          right: '3%',
          width: '0px',
        },
      },
    };
  } else if (
    variation === CAROUSEL_VARIATION.VARIATION2 ||
    variation === CAROUSEL_VARIATION.VARIATION3
  ) {
    carouselConfig = {
      indicators: playing === true ? false : true,
      duration: autoRotateDuration,
      navButtonsAlwaysVisible: false,
      navButtonsAlwaysInvisible: true,
      indicatorContainerProps: {
        style: {
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          zIndex: '1',
          alignItems: 'center',
          bottom: '3%',
          alignSelf: 'center',
          flexBasis: 'content`',
          left: '46%',
        },
      },
    };
  }

  return carouselConfig;
};

export const formateCOIList = (intrestList: COIItemProps[]) => {
  if (isEmpty(intrestList)) return [];
  const newCOIList = intrestList?.map((item: COIItemProps) => {
    return {
      id: item?.id || '',
      sitecoreId: item?.id || '',
      name: item?.name || '',
      key: item?.key?.value || '',
      value: item?.value?.value || '',
      description: item?.description?.value || '',
      communities: fetchCommunityList(item?.communities?.jsonValue),
      category: item?.category?.jsonValue?.fields?.name?.value || '',
      abbreviation: item?.abbreviation?.value || '',
      icon: item?.iconImage?.jsonValue?.value?.src || '',
    };
  });

  return newCOIList.filter((item) => item.category);
};

export const fetchCommunityList = (communitiesList: CommunityItemProps[]) => {
  if (isEmpty(communitiesList)) return [];
  return communitiesList?.map((item: CommunityItemProps) => {
    const id = item?.id?.toUpperCase().replaceAll('-', '');
    return {
      sitecoreId: id,
      key: item?.fields?.key?.value || '',
      value: item?.fields?.value?.value || '',
      description: item?.fields?.description?.value || '',
      icon: item?.fields?.icon?.value || '',
      siteCoreCommunityId: item?.id || '',
    };
  });
};

export const getRespectiveCOIList = (list: any, isCdp = false) => {
  return list.map((a: any) => {
    if (isCdp) return { sitecoreId: a.sitecoreId, id: a.value, text: a.key };
    if (!isCdp) return { conditionId: parseInt(a.value), conditionText: a.key };
    return;
  });
};

export const fetchProfileVerifyTokenKey = () => {
  return sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
};
export const setchProfileVerifyTokenKey = async (myProfileToken: string, expiryTime?: number) => {
  const { data } = await verifyMyProfileEmail(`/api/contacts/VerifyProfile`, {
    verificationToken: myProfileToken,
    expiryTime: expiryTime || 0,
  });
  let response: any;
  if (data.isLinkExpired) {
    response = {};
  } else if (data.isVerifiedEmail) {
    sessionStorage.setItem(PROFILE_VERIFY_TOKEN_KEY, myProfileToken);
    response = await fetchProfileDetailsForCR(myProfileToken);
  }
  return response?.data || {};
};

export const removeTrailingSlash = (url: string) => {
  if (url.endsWith('/')) {
    return url.slice(0, -1);
  }
  return url;
};

export const getSortingArray = (array: string[]) => {
  return array.sort((a, b) => a.localeCompare(b));
};

export const stringToGUID = (strInput: string) => {
  const strOutput = strInput
    ? `{${strInput.replace(
        /([0-f]{8})([0-f]{4})([0-f]{4})([0-f]{4})([0-f]{12})/,
        '$1-$2-$3-$4-$5'
      )}}`
    : '';
  return strOutput;
};

export const mergeTag = (text: string, dataObject: Record<string, any>): string => {
  if (text === undefined) return '';
  const regex = /\[(.*?)\]/g;

  const modifiedText = text.replace(regex, (match, fieldName) => {
    if (dataObject?.hasOwnProperty(fieldName)) {
      return dataObject[fieldName];
    } else {
      return match;
    }
  });

  return modifiedText;
};

export const scrollToSectionPath = (currentHash: string, margin: number, timeout: number) => {
  if (!isEmpty(currentHash)) {
    const element = document.getElementById(currentHash.substr(1));
    if (element) {
      const offsetTop = element.offsetTop;
      delay(
        () =>
          window.scroll({
            top: offsetTop - margin,
            left: 0,
            behavior: 'smooth',
          }),
        timeout,
        'later'
      );
    }
  }
};
