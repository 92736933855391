import { configureStore, combineReducers } from '@reduxjs/toolkit';
import callCenterReducer from '../features/callCenterSlice';
import contactInfoReducer from '../features/contactInfoSlice';
import queryParamsReducer from '../features/queryParamsSlice';
import screenerOutputReducer from '../features/screenerOutputSlice';
import siteSelectionReducer from '../features/siteSelectionSlice';
import modalReducer from '../features/modalSlice';
import configReducer from '../features/configSlice';
import cdpDataReducer from '../features/cdpDataSlice';
import loaderReducer from '../features/loaderSlice';
import selectedLanguageReducer from '../features/selectedLanguageSlice';
import potentialParticipantDetailsReducer from '../features/potentialParticipantDetailsSlice';
import registrationReducer from '../features/registrationSlice';
import storageSession from 'redux-persist/lib/storage/session';
import scrollIdReducer from '../features/scrollIdSlice';
import studySwitcherReducer from '../features/studySwitcherSlice';
import smaReducer from '../features/smaSlice';
import multiScreenerReducer from '../features/multiScreenerSlice';
import crProfileReducer from '../features/crProfileSlice';
import crStudyDetailsReducer from '../features/crStudyDetailsSlice';
import hcpReducer from '../features/hcpSlice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import geolocationReducer from '../features/geolocationSlice';
import cookieBannerReducer from '../features/cookieBannerSlice';
import globalSettingSliceReducer from '../features/globalSettingSlice';
import globalConfigSliceReducer from '../features/globalConfig';
import studyLocationReducer from '../features/studyLocationSlice';
import coveoResult from '../features/coveoSlice';
import crBannerReducer from '../features/crBannerSlice';
import authenticationSlice from '../features/authSlice';
import crQueryParamSlice from '../features/crQueryParamSlice';

export const rootReducer = combineReducers({
  callCenterNumber: callCenterReducer,
  contactInfoDetails: contactInfoReducer,
  queryParams: queryParamsReducer,
  screenerOutput: screenerOutputReducer,
  selectedLanguage: selectedLanguageReducer,
  modal: modalReducer,
  config: configReducer,
  potentialParticipantDetails: potentialParticipantDetailsReducer,
  selectedSite: siteSelectionReducer,
  registrationDetails: registrationReducer,
  geolocation: geolocationReducer,
  cdpData: cdpDataReducer,
  loader: loaderReducer,
  cookieBanner: cookieBannerReducer,
  scrollId: scrollIdReducer,
  globalSettings: globalSettingSliceReducer,
  globalConfig: globalConfigSliceReducer,
  studyLocation: studyLocationReducer,
  studySwitcher: studySwitcherReducer,
  sma: smaReducer,
  multiScreener: multiScreenerReducer,
  coveoResult: coveoResult,
  crProfile: crProfileReducer,
  hcpRegistrationData: hcpReducer,
  crBanner: crBannerReducer,
  crStudyDetails: crStudyDetailsReducer,
  authentication: authenticationSlice,
  crQueryParam: crQueryParamSlice,
});

declare module 'redux-persist' {
  interface PersistorOptions {
    manualPersist?: boolean;
  }
}

export const makeStore = () => {
  const persistConfig = {
    key: 'root',
    storage: storageSession,
    //these three will be stored in localstorage
    whitelist: ['queryParams', 'screenerOutput', 'globalConfig', 'crProfile'],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store: any = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: process.env.NODE_ENV !== 'production',
    //only for demo on dev env then revert this change
    // devTools: true,
  });
  store.__persistor = persistStore(store, { manualPersist: true });
  return store;
};

export const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
