import { createDevLogger } from './devLogger';
import { createProdLogger } from './prodLogger';
import { Logger } from 'pino';

export const createLogger = (): Logger => {
  if (process.env.NODE_ENV === 'development') {
    return createDevLogger();
  } else {
    return createProdLogger();
  }
};

const logger = createLogger();

export { logger };
