import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalSettings {
  tenantKey: any;
  siteName: any;
  journeyID: any;
}

const initialState: GlobalSettings = {
  tenantKey: null,
  siteName: null,
  journeyID: null,
};

const globalSettingSlice = createSlice({
  name: 'addGlobalSetting',
  initialState,
  reducers: {
    addGlobalSettings: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { addGlobalSettings } = globalSettingSlice.actions;

export default globalSettingSlice.reducer;
