import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedLanguageInterface = {
  languageCode: string;
  displayName: string;
  nativeName: string;
  flagIcon: string;
  countryName: string;
  countryISO2: string;
  countryISO3: string;
};
export const initialState: SelectedLanguageInterface = {
  languageCode: 'en',
  displayName: 'English',
  nativeName: 'English',
  flagIcon: '/-/icon/Flags/32x32/flag_usa.png',
  countryName: 'united states',
  countryISO2: 'US',
  countryISO3: 'USA',
};

const selectedLanguageSlice = createSlice({
  name: 'selectedLanguage',
  initialState,
  reducers: {
    selectLanguage: (state, action: PayloadAction<SelectedLanguageInterface>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { selectLanguage } = selectedLanguageSlice.actions;
export default selectedLanguageSlice.reducer;
