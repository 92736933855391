import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ScrollIdState {
  scrollId: string | null;
}

const initialState: ScrollIdState = {
  scrollId: null,
};

const scrollIdSlice = createSlice({
  name: 'scrollId',
  initialState,
  reducers: {
    setScrollId: (state, action: PayloadAction<string | null>) => {
      state.scrollId = action.payload;
    },
  },
});

export const { setScrollId } = scrollIdSlice.actions;

export default scrollIdSlice.reducer;
