import { v4 as uuidv4 } from 'uuid';

const ENROLLMENT_ID_KEY = 'enrollmentID';
const JOURNEY_TYPE = 'journeyType';

//20 days
export const ENROLLMENT_EXPIRY_TIME_FALLBACK = 20;

export const getCookieExpiryTime = (days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  return date;
};

function setCookie(cName: string, cValue: string, expDays: number) {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = cName + '=' + cValue + '; ' + expires + '; path=/; Secure';
}

export function getCookie(name: string): string | null {
  const cookies =
    (typeof document !== 'undefined' && document && document?.cookie?.split(';')) || [];
  if (cookies && cookies?.length) {
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
  }
  return null;
}

export function setJourneyTypeCookie(value: string, expirtyTimeInDays: number): void {
  if (value && expirtyTimeInDays) {
    setCookie(JOURNEY_TYPE, value, expirtyTimeInDays);
  }
}
export function getJourneyTypeCookie(): string | null {
  return getCookie(JOURNEY_TYPE);
}

const setEnrollmentId = (value: string, expirationDays?: number | '' | null): void =>
  setCookie(ENROLLMENT_ID_KEY, value, expirationDays || 20);
export const getEnrollmentId = (): string | null => getCookie(ENROLLMENT_ID_KEY);

// const setEnrollmentId = (value: string): void => localStorage.setItem(ENROLLMENT_ID_KEY, value);
// export const getEnrollmentId = (): string | null => localStorage.getItem(ENROLLMENT_ID_KEY);

/**
 * Gets enrollmentId from local storage if exists else creates new one, stores it to localstorage and returns the same
 * @returns enrollmentId
 */
export const getOrCreateEnrollmentId = (expirationDays?: number | '' | null): string => {
  try {
    if (typeof window !== 'undefined') {
      let enrollmentId = getEnrollmentId();
      if (!enrollmentId || enrollmentId === 'null') {
        enrollmentId = uuidv4();
        setEnrollmentId(enrollmentId, expirationDays);
      }
      return enrollmentId;
    }
    return '';
  } catch (_e) {
    return '';
  }
};
