import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  hcpData: {},
  userRegistered: false,
};

const hcpSlice = createSlice({
  name: 'hcpSlice',
  initialState,
  reducers: {
    setHcpRegistrationData: (state = initialState.coveoResults, action: PayloadAction<any>) => {
      return {
        ...state,
        hcpData: action.payload,
        userRegistered: true,
      };
    },
  },
});

export const { setHcpRegistrationData } = hcpSlice.actions;
export default hcpSlice.reducer;
