import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedSiteMetaData = {
  siteInfo: any;
  selectionType: string;
  shouldInfoWindowVisible: boolean;
};
// type SelectedSite = {
//   siteName: string;
//   siteAddress: string;
//   siteCity: string;
//   siteCountry: string;
//   siteZipCode: string;
//   contactPhoneNumber: string;
//   distance: number;
//   latitude: number;
//   longitude: number;
// };
// eslint-disable-next-line @typescript-eslint/ban-types
export const initialState: SelectedSiteMetaData = {
  siteInfo: {},
  selectionType: '',
  shouldInfoWindowVisible: true,
};

const siteSelectionSlice = createSlice({
  name: 'siteSelection',
  initialState,
  reducers: {
    siteSelection: (_state, action: PayloadAction<SelectedSiteMetaData>) => ({
      ...action.payload,
    }),
  },
});

export const { siteSelection } = siteSelectionSlice.actions;
export default siteSelectionSlice.reducer;
