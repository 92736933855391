import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  isOpen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalData: (state = initialState, action: PayloadAction<any>) => ({
      state,
      ...action.payload,
    }),
    showModal: (state = initialState) => ({
      ...state,
      isOpen: true,
    }),
    hideModal: (state = initialState) => ({
      ...state,
      isOpen: false,
    }),
  },
});

export const { setModalData, showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
