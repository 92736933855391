import axios from 'axios';
import { LifeLinkRequest, LifeLinkResponse } from '../../types/LifeLink';
import { logger } from '../../logger';
import { removePIIDataFromPayload } from '../../../src/transformers/contactInfo.transform';
import getBaseURL from './getBaseURL';

const get = async (url: string): Promise<any> => {
  try {
    const response = await axios.get('/api/dap-api', { url: url });
    logger.info(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      response: response?.data,
      payload: {},
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      error: errorMessage,
      payload: {},
    });
    throw error;
  }
};

const post = async (url: string, payload?: any): Promise<any> => {
  try {
    const response = await axios.post('/api/dap-api', {
      url,
      data: payload,
    });
    logger.info(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      error: errorMessage,
      payload: payload,
    });
    throw error;
  }
};

const put = async (url: string, payload: any): Promise<any> => {
  try {
    const response = await axios.put('/api/dap-api', {
      url,
      data: payload,
    });
    logger.info(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      response: response?.data,
      payload: payload,
    });
    return response;
  } catch (error: any) {
    const errorMessage = error?.response?.data?.message || '';
    logger.error(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      error: errorMessage,
      payload: payload,
    });
    throw error;
  }
};

const postToLifeLink = async (requestPayload: LifeLinkRequest): Promise<LifeLinkResponse> => {
  try {
    const response = await post('/LifeLink/PostToLifeLink', requestPayload);
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

const savePatientDelegateDetails = async (url: string, payload: any): Promise<any> => {
  const payloadWithoutPIIData = removePIIDataFromPayload(payload);
  try {
    const response = await axios.post('/api/dap-api', { url, data: payload });
    logger.info(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      response: response?.data,
      payload: payloadWithoutPIIData,
    });
    return response;
  } catch (error: any) {
    logger.error(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
      error: error?.message,
      payload: payloadWithoutPIIData,
    });
    throw error;
  }
};

const updateLLDetailsTODB = async (
  enrollmentId: string,
  tenantKey: string,
  appointmentStatus: string,
  potentialId: string,
  patientId: string
): Promise<void> => {
  const baseUrl = getBaseURL();
  const url = `/LifeLink/UpdateLLdetailsToDB?enrollmentId=${enrollmentId}&tenantKey=${tenantKey}&appointmentStatus=${appointmentStatus}&potentialId=${potentialId}&patientId=${patientId}&baseUrl=${baseUrl}`;
  try {
    const response = await post(url, {});
    return response.data;
  } catch (_error) {}
};

const addParticipantOnLoad = async (payload: any) => {
  try {
    const { data: response } = await post(
      '/Participant/Add',
      payload
      // potentialParticipantDetailsFromStore
    );
    return response;
  } catch (_e: any) {}
};

export {
  get,
  post,
  put,
  postToLifeLink,
  updateLLDetailsTODB,
  savePatientDelegateDetails,
  addParticipantOnLoad,
};
