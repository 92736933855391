export enum PatientType {
  ADULT = 'adult',
  MINOR = 'minor',
  CAREGIVER = 'caregiver',
}

export enum RedirectTo {
  MINORDNQ = 'minor dnq',
  MINORDNQGUARDIAN = 'minor dnq guardian',
  MINORDNQNOTINRANGE = 'minor dnq not in range',
}
