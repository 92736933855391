import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ILocation {
  latitude: number | undefined;
  longitude: number | undefined;
  zipcode: string | undefined | null;
}

export interface IGeolocationSlice {
  location: ILocation | null;
  error: string | null | undefined;
  googleScriptLoaded?: boolean;
}

const initialState: IGeolocationSlice = {
  location: null,
  error: null,
  googleScriptLoaded: false,
};

const geolocation = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocation: (state = initialState, action: PayloadAction<IGeolocationSlice>) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    updateZipcode: (state = initialState, action: any) => {
      return {
        ...state,
        location: {
          ...state.location,
          latitude: undefined,
          longitude: undefined,
          zipcode: action?.payload?.zipcode,
          state: action?.payload?.state,
          city: undefined,
        },
      };
    },
    setGoogleScriptLoaded: (state = initialState) => {
      return {
        ...state,
        googleScriptLoaded: true,
      };
    },
  },
});

export const { setGeolocation, updateZipcode, setGoogleScriptLoaded } = geolocation.actions;
export default geolocation.reducer;
