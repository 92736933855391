import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GlobalConfig {
  isSetGlobalSetting: boolean;
  lastVisitedComponentId: string;
}

const initialState: GlobalConfig = {
  isSetGlobalSetting: false,
  lastVisitedComponentId: '',
};

const globalConfigSlice = createSlice({
  name: 'globalConfig',
  initialState,
  reducers: {
    updateGlobalSettings: (state, action: PayloadAction<boolean>) => {
      state.isSetGlobalSetting = !!action.payload;
    },

    updateLastVisitedComponentId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        lastVisitedComponentId: action.payload,
      };
    },

    resetLastVisitedComponentId: (state) => ({
      ...state,
      lastVisitedComponentId: '',
    }),
  },
});

export const { updateGlobalSettings, resetLastVisitedComponentId, updateLastVisitedComponentId } =
  globalConfigSlice.actions;

export default globalConfigSlice.reducer;
