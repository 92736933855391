import LogHttpClient from './logHttpClient';
import { getCookie, getJourneyTypeCookie } from '../lib/utils/enrollmentUtil';
import { store } from '../store/store';

type SerializerFn = (value: any) => any;
type Level = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace';

interface Bindings {
  level?: Level | string;
  serializers?: { [key: string]: SerializerFn };
  [key: string]: any;
}

interface LogEvent {
  ts: number;
  messages: any[];
  bindings: Bindings[];
  level: {
    label: string;
    value: number;
  };
}

const formatPinoBrowserLogEvent = (logEvent: LogEvent): any => {
  const {
    ts,
    messages,
    // level: { value: levelValue },
  } = logEvent;

  const timestamp = ts;
  // const defaultMetadata = {
  //   level: levelValue,
  //   browser: true,
  // };

  return {
    metadata: messages[1],
    log_entry: messages[0],
    timestamp,
  };
};

const createPinoBrowserSend = (apiUrl: string): any => {
  const client = new LogHttpClient(apiUrl);
  return (_level: Level | number, logEvent: LogEvent) => {
    const globalSettings = store.getState()?.globalSettings;
    const logEventData = formatPinoBrowserLogEvent(logEvent);
    const { metadata, log_entry, timestamp } = logEventData;
    const payload = {
      time: Math.trunc(timestamp / 1000),
      host: window.location.host,
      source: `${globalSettings['siteName']}_${process.env.ENV_NAME}`,
      event: {
        apiUrl: log_entry,
        data: {
          logLevel: _level,
          currentPageUrl: window.location.pathname,
          ppID: getCookie('bx_guest_ref'),
          enrollmentId: getCookie('enrollmentID'),
          journeyStep: getCookie('journeyStep'),
          journeyStatus: getCookie('journeyStatus'),
          //journeyID: globalSettings['journeyID'],
          journeyType: getJourneyTypeCookie(),
          tenantKey: globalSettings['tenantKey'],
          msg:
            typeof metadata === 'string' || typeof metadata === 'boolean'
              ? metadata
              : {
                  ...metadata,
                },
        },
      },
    };
    //const payload = { ...metadata, time: timestamp, msg: timestamp };
    if (!(process.env.NEXT_PUBLIC_SPLUNK_LOG_ENABLED === 'false')) {
      client.postLogEvents(payload);
    }
    return;
  };
};

export { formatPinoBrowserLogEvent, createPinoBrowserSend };
