import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  registration: {
    email: '',
    agree: '',
  },
  aboutYourSelf: {
    firstName: '',
    lastName: '',
  },
  dob: {},
  selectedCOIList: [],
  patientDetails: [],
  locationDetails: {
    zipcode: '',
    travelDistance: '',
    homeTrial: '',
    latitude: null,
    longitude: null,
  },
  contactPreference: {},
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    updateRegistration: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        registration: { ...state.registration, ...action.payload },
      };
    },
    setAboutYourself: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        aboutYourSelf: { ...state.aboutYourSelf, ...action.payload },
      };
    },
    setSelectedCOIList: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedCOIList: action.payload,
      };
    },
    setPatientDetails: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        patientDetails:
          state.patientDetails.map((details: any) => details.index).indexOf(action.payload.index) >
          -1
            ? state.patientDetails.filter((details: any) => details.index !== action.payload.index)
            : [...state.patientDetails, action.payload],
      };
    },
    setZipCode: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          zipcode: action.payload,
        },
      };
    },
    setTravelDistance: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          travelDistance: action.payload,
        },
      };
    },
    setHomeTrial: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          homeTrial: action.payload,
        },
      };
    },
    setMessagingConsent: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference,
          textMessaging: action.payload,
        },
      };
    },
    setDOBAndGender: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        dob: {
          ...state.dob,
          ...action.payload,
        },
      };
    },
    setContactPreference: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        contactPreference: {
          ...state.contactPreference,
          ...action.payload,
        },
      };
    },
    clearRegistrationData: () => {
      return {
        ...initialState,
      };
    },
    setLatAndLong: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        locationDetails: {
          ...state.locationDetails,
          latitude: action?.payload?.latitude || null,
          longitude: action?.payload?.longitude || null,
        },
      };
    },
  },
});

export const {
  setAboutYourself,
  setPatientDetails,
  setSelectedCOIList,
  setZipCode,
  setTravelDistance,
  setHomeTrial,
  setContactPreference,
  setMessagingConsent,
  updateRegistration,
  clearRegistrationData,
  setDOBAndGender,
  setLatAndLong,
} = registrationSlice.actions;
export default registrationSlice.reducer;
