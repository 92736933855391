import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  coveoResults: {},
  distance: 50,
  maxDistance: 0,
  showAnyLocation: false,
  isVirtual: false,
  zipCode: '',
  dayOfBirth: null,
};

const coveoResult = createSlice({
  name: 'coveo-result',
  initialState,
  reducers: {
    setCoveoResult: (state = initialState.coveoResults, action: PayloadAction<any>) => {
      return {
        ...state,
        coveoResults: action.payload,
      };
    },
    setDistance: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        distance: action.payload,
      };
    },
    setMaxDistance: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        maxDistance: action.payload,
      };
    },
    setShowAnyLocation: (state) => {
      return {
        ...state,
        showAnyLocation: !state.showAnyLocation,
      };
    },
    setIsVirtual: (state) => {
      return {
        ...state,
        isVirtual: !state.isVirtual,
      };
    },
    setZipCode: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        zipCode: action.payload,
      };
    },
    setDayOfBirth: (state, action: PayloadAction<string | null>) => {
      return {
        ...state,
        dayOfBirth: action.payload,
      };
    },
  },
});

export const {
  setCoveoResult,
  setDistance,
  setMaxDistance,
  setShowAnyLocation,
  setZipCode,
  setIsVirtual,
  setDayOfBirth,
} = coveoResult.actions;
export default coveoResult.reducer;
