import { getConsentsData } from '../components/ContactInfo/helper';
import { getDialingCodeByISOCode } from '../lib/utils/dialingCodeList';
import { AutoCompleteLocationInfo } from 'src/features/contactInfoSlice';
import { store } from '../store/store';
import { JOURNEYS, SCREENER_STATUS } from '../constants';
import omit from 'lodash/omit';
import { PatientType } from '../lib/type/userJourney.types';
import { getCookie, getOrCreateEnrollmentId } from '../lib/utils/enrollmentUtil';
import getReferralId from '../lib/utils/getReferralId';

export interface ILanguageListField {
  languageCode: string;
  displayName: string;
  nativeName: string;
  flagIcon: string;
  countryName: string;
  countryISO2: string;
  countryISO3: string;
}

const PatientEnrolledBy = {
  ADULT: 'Adult',
  GUARDIAN: 'Guardian',
  CAREGIVER: 'Caregiver',
};
//TODO: what about other genders
export const GENDER = {
  M: 'Male',
  F: 'Female',
};

const RecordLastUpdateBy = 'Application';

export const pad = (n: any) => {
  if (n < 10) return '0' + n;
  return n;
};

export const MONTH_MAPPER_REGISTRATION = {
  4: 'April',
  8: 'August',
  12: 'December',
  2: 'February',
  1: 'January',
  7: 'July',
  6: 'June',
  3: 'March',
  5: 'May',
  11: 'November',
  10: 'October',
  9: 'September',
};

export const MONTH_MAPPER = {
  April: 4,
  August: 8,
  December: 12,
  February: 2,
  January: 1,
  July: 7,
  June: 6,
  March: 3,
  May: 5,
  November: 11,
  October: 10,
  September: 9,
};

export const formatDOB = (dayValue: string, monthValue: string, yearValue: string) => {
  let fromattedDate = '';
  if (dayValue && monthValue && yearValue) {
    fromattedDate = `${yearValue}-${pad(
      MONTH_MAPPER[monthValue as keyof typeof MONTH_MAPPER]
    )}-${pad(dayValue)}`;
  } else if (monthValue && yearValue) {
    fromattedDate = `${yearValue}-${pad(MONTH_MAPPER[monthValue as keyof typeof MONTH_MAPPER])}`;
  } else if (yearValue) {
    fromattedDate = `${yearValue}`;
  }
  return fromattedDate;
};

function isEmpty(val: any) {
  return val === undefined || val == null || val === '' ? true : false;
}

const getPartialDefaultDelegateData = (ipAddress) => ({
  contactAddress2: null,
  ipAddress: ipAddress,
  recordFirstAddedDate: null,
  recordLastModifiedDate: null,
  emailOptInDate: null,
  emailOptOutDate: null,
  unsubscribedDelegateFromEmail: false,
});

const getPartialDefaultPatientData = () => ({
  referralId: null,
  converted: null,
  registrationDateTime: null,
  recordFirstAddedDate: null,
  recordLastModifiedDate: null,
});

export const formatAdultFormData = (
  formData: any,
  selectedLanguage: ILanguageListField,
  potentialParticipantID: string | null,
  potentialId: number,
  autoCompleteLocationInfo: AutoCompleteLocationInfo,
  tenantKey: string,
  enrollmentId: string,
  patientReferralStatus: string,
  selectedPlace: any
) => {
  const {
    FirstName,
    LastName,
    Email,
    Zipcode,
    State,
    isMobile,
    ContactNumber,
    Gender,
    Day,
    Month,
    Year,
    Address,
    City,
    PreferredContactTime,
    UserTimeZone,
  } = formData;

  const state = store.getState();
  let currentScreenerStatus;
  let qualifiedProtocol;
  if (state?.cdpState?.journeyType === JOURNEYS.JOURNEY_03) {
    currentScreenerStatus = null;
    qualifiedProtocol = null;
  } else {
    currentScreenerStatus = state?.screenerOutput?.pSec
      ? SCREENER_STATUS.QUALIFIED
      : SCREENER_STATUS.DISQUALIFIED;
    qualifiedProtocol = state?.screenerOutput?.pSec ? state?.screenerOutput?.pSec : '';
  }
  const { consentInfo, secondaryConsentInfo } = getConsentsData(formData);
  const newConsentInfo = {
    ...consentInfo,
    tenantKey,
    enrollmentId,
  };

  const newSecondaryConsentInfo = {
    ...secondaryConsentInfo,
    tenantKey,
    enrollmentId,
  };
  const sitecode = selectedPlace?.siteInfo?.siteCode || '';
  const referralId = sitecode
    ? getReferralId(getOrCreateEnrollmentId(), sitecode)
    : getPartialDefaultPatientData().referralId;
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  return {
    patient: {
      potentialId,
      potentialParticipant_ID: potentialParticipantID,
      tenantKey,
      enrollmentId,
      patientType: PatientType.ADULT,
      firstName: isEmpty(FirstName) ? null : FirstName,
      lastName: isEmpty(LastName) ? null : LastName,
      emailAddress: isEmpty(Email) ? null : Email,
      countryName: selectedLanguage.countryISO2,
      dialingCode: getDialingCodeByISOCode(selectedLanguage?.countryISO2),
      zipcode: isEmpty(Zipcode) ? null : Zipcode,
      state: State,
      contactPhoneNumber: isEmpty(ContactNumber) ? null : ContactNumber,
      mobileNumber: isMobile ? (isEmpty(ContactNumber) ? null : ContactNumber) : null,
      gender: isEmpty(Gender) ? null : Gender,
      dateofbirth: formatDOB(Day, Month, Year),
      address: isEmpty(Address) ? null : Address,
      contactCity: isEmpty(City) ? null : City,
      preferredContactTime: isEmpty(PreferredContactTime) ? null : PreferredContactTime,
      userTimeZone: isEmpty(UserTimeZone) ? null : UserTimeZone,
      delegateId: null,
      latitude: autoCompleteLocationInfo['patient']?.lat
        ? autoCompleteLocationInfo['patient']?.lat.toString()
        : '',
      longitude: autoCompleteLocationInfo['patient']?.lng
        ? autoCompleteLocationInfo['patient']?.lng.toString()
        : '',
    },
    patientDetails: {
      ...getPartialDefaultPatientData(),
      preferredLanguage: selectedLanguage?.languageCode?.split('-')[0].toLocaleLowerCase(),
      patientEnrolledBy: PatientEnrolledBy.ADULT,
      recordLastUpdatedBy: RecordLastUpdateBy,
      unsubscribedFromEmailCommunications: false,
      patientReferralStatus: patientReferralStatus,
      referralId,
    },
    delegate: {},
    consentInfo: newConsentInfo,
    secondaryConsentInfo: newSecondaryConsentInfo,
    currentScreenerStatus: currentScreenerStatus,
    qualifiedProtocol: qualifiedProtocol,
    browserId: getCookie(cookieName),
  };
};

export const formatMinorFormData = (
  formData: any,
  selectedLanguage: ILanguageListField,
  potentialParticipantID: string | null,
  potentialId: number,
  autoCompleteLocationInfo: AutoCompleteLocationInfo,
  tenantKey: string,
  enrollmentId: string,
  patientReferralStatus: string,
  selectedPlace: any,
  ipAddress: any
) => {
  const state = store.getState();
  let currentScreenerStatus;
  let qualifiedProtocol;
  if (state?.cdpState?.journeyType === JOURNEYS.JOURNEY_03) {
    currentScreenerStatus = null;
    qualifiedProtocol = null;
  } else {
    currentScreenerStatus = state?.screenerOutput?.pSec
      ? SCREENER_STATUS.QUALIFIED
      : SCREENER_STATUS.DISQUALIFIED;
    qualifiedProtocol = state?.screenerOutput?.pSec ? state?.screenerOutput?.pSec : '';
  }

  const { consentInfo, secondaryConsentInfo } = getConsentsData(formData);
  const newConsentInfo = {
    ...consentInfo,
    tenantKey,
    enrollmentId,
  };

  const newSecondaryConsentInfo = {
    ...secondaryConsentInfo,
    tenantKey,
    enrollmentId,
  };
  const sitecode = selectedPlace?.siteInfo?.siteCode || '';
  const referralId = sitecode
    ? getReferralId(getOrCreateEnrollmentId(), sitecode)
    : getPartialDefaultPatientData().referralId;

  const {
    ParticipantFirstName,
    ParticipantLastName,
    ParticipantZipcode,
    ParticipantState,
    UserTimeZone,
    isMobile,
    Gender,
    Day,
    Month,
    Year,
    PreferredContactTime,
    ParentFirstName,
    ParentLastName,
    ParentEmail,
    ParentContactNumber,
    ParentAddress,
    ParentCity,
    ParentState,
    ZipCode,
    ParticipantEmail,
    ParticipantAddress,
    ParticipantCity,
  } = formData;
  /**
    these fields are not available on UI form, but provide some key for future use
    if those key gets added in future no code change required on ui side
    1. ParticipantEmail
    2. ParticipantAddress
    3. ParticipantCity
    for patient contactPhoneNumber, mobileNumber will be null always
 */
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  return {
    patient: {
      potentialId,
      potentialParticipant_ID: potentialParticipantID,
      tenantKey,
      enrollmentId,
      patientType: PatientType.MINOR,
      firstName: isEmpty(ParticipantFirstName) ? null : ParticipantFirstName,
      lastName: isEmpty(ParticipantLastName) ? null : ParticipantLastName,
      emailAddress: isEmpty(ParticipantEmail) ? null : ParticipantEmail, // <-------- not in ui
      countryName: selectedLanguage.countryISO2,
      dialingCode: getDialingCodeByISOCode(selectedLanguage?.countryISO2),
      zipcode: isEmpty(ParticipantZipcode) ? null : ParticipantZipcode,
      state: isEmpty(ParticipantState) ? null : ParticipantState,
      contactPhoneNumber: null, // <-------- not in ui
      mobileNumber: null, // <-------- not in ui
      gender: isEmpty(Gender) ? null : Gender,
      dateofbirth: formatDOB(Day, Month, Year), //
      address: isEmpty(ParticipantAddress) ? null : ParticipantAddress, // <-------- not in ui
      contactCity: isEmpty(ParticipantCity) ? null : ParticipantCity, // <-------- not in ui
      preferredContactTime: isEmpty(PreferredContactTime) ? null : PreferredContactTime,
      userTimeZone: isEmpty(UserTimeZone) ? null : UserTimeZone,
      delegateId: null,
      latitude: autoCompleteLocationInfo['patient'].lat
        ? autoCompleteLocationInfo['patient'].lat.toString()
        : '',
      longitude: autoCompleteLocationInfo['patient'].lng
        ? autoCompleteLocationInfo['patient'].lng.toString()
        : '',
    },
    patientDetails: {
      ...getPartialDefaultPatientData(),
      preferredLanguage: selectedLanguage?.languageCode?.split('-')[0].toLocaleLowerCase(),
      patientEnrolledBy: PatientEnrolledBy.GUARDIAN,
      recordLastUpdatedBy: RecordLastUpdateBy,
      unsubscribedFromEmailCommunications: false,
      patientReferralStatus: patientReferralStatus,
      referralId,
    },
    delegate: {
      firstName: isEmpty(ParentFirstName) ? null : ParentFirstName,
      lastName: isEmpty(ParentLastName) ? null : ParentLastName,
      emailAddress: isEmpty(ParentEmail) ? null : ParentEmail,
      contactPhoneNumber: isEmpty(ParentContactNumber) ? null : ParentContactNumber,
      address: isEmpty(ParentAddress) ? null : ParentAddress,
      contactCity: isEmpty(ParentCity) ? null : ParentCity,
      state: isEmpty(ParentState) ? null : ParentState,
      zipcode: isEmpty(ZipCode) ? null : ZipCode,
      mobileNumber: isMobile ? (isEmpty(ParentContactNumber) ? null : ParentContactNumber) : null,
      latitude: autoCompleteLocationInfo['delegate'].lat
        ? autoCompleteLocationInfo['delegate'].lat.toString()
        : '',
      longitude: autoCompleteLocationInfo['delegate'].lng
        ? autoCompleteLocationInfo['delegate'].lng.toString()
        : '',
      ...getPartialDefaultDelegateData(ipAddress),
    },
    consentInfo: newConsentInfo,
    secondaryConsentInfo: newSecondaryConsentInfo,
    currentScreenerStatus: currentScreenerStatus,
    qualifiedProtocol: qualifiedProtocol,
    browserId: getCookie(cookieName),
  };
};

export const formatCaregiverFormData = (
  formData: any,
  selectedLanguage: ILanguageListField,
  potentialParticipantID: string | null,
  potentialId: number,
  autoCompleteLocationInfo: AutoCompleteLocationInfo,
  tenantKey: string,
  enrollmentId: string,
  patientReferralStatus: string,
  selectedPlace: any,
  ipAddress: any
) => {
  const state = store.getState();
  let currentScreenerStatus;
  let qualifiedProtocol;
  if (state?.cdpState?.journeyType === JOURNEYS.JOURNEY_03) {
    currentScreenerStatus = null;
    qualifiedProtocol = null;
  } else {
    currentScreenerStatus = state?.screenerOutput?.pSec
      ? SCREENER_STATUS.QUALIFIED
      : SCREENER_STATUS.DISQUALIFIED;
    qualifiedProtocol = state?.screenerOutput?.pSec ? state?.screenerOutput?.pSec : '';
  }

  const { consentInfo, secondaryConsentInfo } = getConsentsData(formData);
  const newConsentInfo = {
    ...consentInfo,
    delegateId: null,
    tenantKey,
    enrollmentId,
  };

  const newSecondaryConsentInfo = {
    ...secondaryConsentInfo,
    tenantKey,
    enrollmentId,
  };
  const sitecode = selectedPlace?.siteInfo?.siteCode || '';
  const referralId = sitecode
    ? getReferralId(getOrCreateEnrollmentId(), sitecode)
    : getPartialDefaultPatientData().referralId;

  const {
    ParticipantFirstName,
    ParticipantLastName,
    ParticipantEmail,
    isMobile,
    Gender,
    Day,
    Month,
    Year,
    PreferredContactTime,
    UserTimeZone,
    CaregiverFirstName,
    CaregiverLastName,
    CaregiverEmail,
    CaregiverAddress,
    CaregiverContactNumber,
    CaregiverCity,
    CaregiverState,
    CaregiverZipCode,
    ParticipantState,
    ParticipantZipcode,
    ParticipantAddress,
    ParticipantCity,
  } = formData;

  /**
    these fields are not available on UI form, but provide some key for future use
    if those key gets added in future no code change required on ui side
    1. ParticipantEmail
    2. ParticipantAddress
    3. ParticipantCity
    for patient contactPhoneNumber, mobileNumber will be null always
 */
  const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
  return {
    patient: {
      potentialId,
      potentialParticipant_ID: potentialParticipantID,
      tenantKey,
      enrollmentId,
      patientType: PatientType.CAREGIVER,
      firstName: isEmpty(ParticipantFirstName) ? null : ParticipantFirstName,
      lastName: isEmpty(ParticipantLastName) ? null : ParticipantLastName,
      emailAddress: isEmpty(ParticipantEmail) ? null : ParticipantEmail,
      countryName: selectedLanguage.countryISO2,
      dialingCode: getDialingCodeByISOCode(selectedLanguage?.countryISO2),
      zipcode: isEmpty(ParticipantZipcode) ? null : ParticipantZipcode, // <-------- not in ui (for variant 1)
      state: isEmpty(ParticipantState) ? null : ParticipantState, // <-------- not in ui (for variant 1)
      contactPhoneNumber: null, // <-------- not in ui
      mobileNumber: null, // <-------- not in ui
      gender: isEmpty(Gender) ? null : Gender,
      dateofbirth: formatDOB(Day, Month, Year), //
      address: isEmpty(ParticipantAddress) ? null : ParticipantAddress, // <-------- not in ui
      contactCity: isEmpty(ParticipantCity) ? null : ParticipantCity, // <-------- not in ui
      preferredContactTime: isEmpty(PreferredContactTime) ? null : PreferredContactTime,
      userTimeZone: isEmpty(UserTimeZone) ? null : UserTimeZone,
      delegateId: null,
      latitude: autoCompleteLocationInfo['patient'].lat
        ? autoCompleteLocationInfo['patient'].lat.toString()
        : '',
      longitude: autoCompleteLocationInfo['patient'].lng
        ? autoCompleteLocationInfo['patient'].lng.toString()
        : '',
    },
    patientDetails: {
      ...getPartialDefaultPatientData(),
      preferredLanguage: selectedLanguage?.languageCode?.split('-')[0].toLocaleLowerCase(),
      patientEnrolledBy: PatientEnrolledBy.CAREGIVER,
      recordLastUpdatedBy: RecordLastUpdateBy,
      unsubscribedFromEmailCommunications: false,
      patientReferralStatus: patientReferralStatus,
      referralId,
    },
    delegate: {
      firstName: isEmpty(CaregiverFirstName) ? null : CaregiverFirstName,
      lastName: isEmpty(CaregiverLastName) ? null : CaregiverLastName,
      emailAddress: isEmpty(CaregiverEmail) ? null : CaregiverEmail,
      contactPhoneNumber: isEmpty(CaregiverContactNumber) ? null : CaregiverContactNumber,
      address: isEmpty(CaregiverAddress) ? null : CaregiverAddress,
      contactCity: isEmpty(CaregiverCity) ? null : CaregiverCity,
      state: isEmpty(CaregiverState) ? null : CaregiverState,
      zipcode: isEmpty(CaregiverZipCode) ? null : CaregiverZipCode,
      mobileNumber: isMobile
        ? isEmpty(CaregiverContactNumber)
          ? null
          : CaregiverContactNumber
        : null,
      latitude: autoCompleteLocationInfo['delegate'].lat
        ? autoCompleteLocationInfo['delegate'].lat.toString()
        : '',
      longitude: autoCompleteLocationInfo['delegate'].lng
        ? autoCompleteLocationInfo['delegate'].lng.toString()
        : '',
      ...getPartialDefaultDelegateData(ipAddress),
    },
    consentInfo: newConsentInfo,
    secondaryConsentInfo: newSecondaryConsentInfo,
    currentScreenerStatus: currentScreenerStatus,
    qualifiedProtocol: qualifiedProtocol,
    browserId: getCookie(cookieName),
  };
};

export const removePIIDataFromPayload = (payload: any) => {
  return omit(payload, [
    'patient.firstName',
    'patient.lastName',
    'patient.emailAddress',
    'patient.contactPhoneNumber',
    'patient.mobileNumber',
    'patient.dateofbirth',
    'patient.address',
    'patient.latitude',
    'patient.longitude',
    'delegate.firstName',
    'delegate.lastName',
    'delegate.emailAddress',
    'delegate.contactPhoneNumber',
    'delegate.mobileNumber',
    'delegate.address',
  ]);
};
