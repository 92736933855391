import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IPotentialParticipantDetails {
  potentialParticipant_ID: string | null;
  enrollmentId: string;
  ipAddress?: string;
  sessionStartDateTime?: null | string;
  sessionEndDateTime?: null | string;
  journeyStatus?: string;
  journeyId?: string;
  tenantKey?: string;
  projectTherapeuticArea?: string;
  source?: string;
  dataControllerOrg?: string;
  potentialId?: string | number;
}

export const initialState: IPotentialParticipantDetails = {
  potentialParticipant_ID: '',
  ipAddress: '',
  sessionStartDateTime: '2023-06-08T05:16:31.624Z',
  sessionEndDateTime: '2023-06-08T05:16:31.624Z',
  journeyStatus: '',
  journeyId: '',
  tenantKey: '',
  enrollmentId: '',
  projectTherapeuticArea: '',
  source: '',
  dataControllerOrg: '',
  potentialId: '',
};

const potentialParticipantDetailsSlice = createSlice({
  name: 'potentialParticipantDetails',
  initialState,
  reducers: {
    updatepotentialParticipantDetails: (
      state,
      action: PayloadAction<IPotentialParticipantDetails>
    ) => ({
      ...state,
      ...action.payload,
    }),
    addpotentialId: (state, action: PayloadAction<number>) => ({
      ...state,
      potentialId: action.payload,
    }),
    resetPotentialId: (state) => ({
      ...state,
      potentialId: '',
    }),
    addpotentialParticipantId: (state, action: PayloadAction<string>) => ({
      ...state,
      potentialParticipant_ID: action.payload,
    }),
  },
});

export const {
  updatepotentialParticipantDetails,
  addpotentialId,
  addpotentialParticipantId,
  resetPotentialId,
} = potentialParticipantDetailsSlice.actions;
export default potentialParticipantDetailsSlice.reducer;
