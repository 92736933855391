import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type PhNumber = { value: string };
export const initialState: PhNumber = {
  value: '',
};

const callCenterSlice = createSlice({
  name: 'callCenterNumber',
  initialState,
  reducers: {
    add: (_state, action: PayloadAction<PhNumber>) => ({
      ...action.payload,
    }),
  },
});

export const { add } = callCenterSlice.actions;
export default callCenterSlice.reducer;
