import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IQueryParamObj {
  queryParamKey: string;
  queryParamValue: string;
}
interface IConfigState {
  isNotificationBannerOpen?: null | boolean;
  queryParamList?: IQueryParamObj[];
  selectedCommunityId?: null | string;
  isCommunityExpanded?: boolean;
}

const initialState: IConfigState = {
  isNotificationBannerOpen: null,
  queryParamList: [],
  selectedCommunityId: null,
  isCommunityExpanded: false,
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (state = initialState, action: PayloadAction<IConfigState>) => {
      return {
        ...state,
        isNotificationBannerOpen: action.payload.isNotificationBannerOpen,
      };
    },
    hideNotificationBanner: (state = initialState, action: PayloadAction<IConfigState>) => ({
      ...state,
      isNotificationBannerOpen: action.payload.isNotificationBannerOpen,
    }),
    updateQueryParamList: (state = initialState, action) => ({
      ...state,
      queryParamList: action.payload,
    }),
    updateSelectedCommunityId: (state = initialState, action) => ({
      ...state,
      selectedCommunityId: action.payload,
    }),
    updateCommunityExpanded: (state = initialState, action) => ({
      ...state,
      isCommunityExpanded: action.payload,
    }),
  },
});

export const {
  setConfig,
  hideNotificationBanner,
  updateQueryParamList,
  updateSelectedCommunityId,
  updateCommunityExpanded,
} = configSlice.actions;
export default configSlice.reducer;
