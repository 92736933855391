import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StudyLocation {
  id: number;
  name: string;
  [key: string]: any;
}

interface StudyLocationState {
  locations: StudyLocation[];
  selectedLocation: StudyLocation | null;
  hoveredLocation: StudyLocation | null;
  isLoading: false;
  searchZipcode: null | undefined;
}

const initialState: StudyLocationState = {
  locations: [],
  selectedLocation: null,
  hoveredLocation: null,
  isLoading: false,
  searchZipcode: null,
};

const studyLocationSlice = createSlice({
  name: 'studyLocation',
  initialState,
  reducers: {
    setStudyLocations: (state, action: PayloadAction<any>) => {
      state.locations = [...action.payload];
    },
    selectStudyLocation: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedLocation: action.payload,
      };
    },
    setHoveredLocation: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        hoveredLocation: action.payload,
      };
    },
    setLoaderForStudyLocaton: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    },
    updateSearchZipcode: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        searchZipcode: action.payload,
      };
    },
  },
});

export const {
  setStudyLocations,
  selectStudyLocation,
  setHoveredLocation,
  setLoaderForStudyLocaton,
  updateSearchZipcode,
} = studyLocationSlice.actions;

export default studyLocationSlice.reducer;
