import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IOptionItem {
  name: string;
  value: string;
}
interface IStudySwitcherSlice {
  selectedOptionName: string;
  selectedOptionValue: string;
  options: IOptionItem[];
  isConditionSwitcher: boolean;
}
export const initialState: IStudySwitcherSlice = {
  selectedOptionName: '',
  selectedOptionValue: '',
  options: [],
  isConditionSwitcher: false,
};

const studySwitcherSlice = createSlice({
  name: 'studySwitcher',
  initialState,
  reducers: {
    updateOptions: (state, action: PayloadAction<any>) => ({
      ...state,
      options: action.payload,
    }),
    updateSelectedOptionName: (state, action: PayloadAction<any>) => ({
      ...state,
      selectedOptionName: action.payload,
    }),
    updateSelectedOptionValue: (state, action: PayloadAction<any>) => ({
      ...state,
      selectedOptionValue: action.payload,
    }),
    setIsConditionSwitcher: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isConditionSwitcher: action.payload,
    }),
  },
});

export const {
  updateOptions,
  updateSelectedOptionName,
  updateSelectedOptionValue,
  setIsConditionSwitcher,
} = studySwitcherSlice.actions;
export default studySwitcherSlice.reducer;
