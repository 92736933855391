import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
  selectedStudy: null,
};

const crStudyDetailsSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    setSelectedStudy: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedStudy: action.payload,
      };
    },
  },
});

export const { setSelectedStudy } = crStudyDetailsSlice.actions;
export default crStudyDetailsSlice.reducer;
