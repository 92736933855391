import { Theme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { hexToCSSFilter } from 'hex-to-css-filter';

// default configuration for hex to css filter
const config = {
  acceptanceLossPercentage: 1,
};

// Utility function to convert px to rem
const pxToRem = (value: number) => {
  return `${value / 16}rem`;
};

interface NeutralColorPalette {
  main: React.CSSProperties['color'];
}
const generateAllNeutralColor = () => {
  const neutralColors: { [key: string]: React.CSSProperties['color'] } = {
    neutral0: '#FFFFFF',
    neutral1: '#F6F7FB',
    neutral2: '#F2F2F2',
    neutral3: '#E9E9E9',
    neutral4: '#D9D9D9',
    neutral5: '#B5B5B5',
    neutral6: '#999999',
    neutral7: '#595959',
    neutral8: '#444444',
    neutral9: '#000000',
    neutral10: '#b1b1b1',
    neutral11: '#757575',
    neutral12: '#2F2E41',
  };
  const neutralColorsList: { [key: string]: NeutralColorPalette } = {};
  for (const color in neutralColors) {
    neutralColorsList[color] = {
      main: neutralColors[color],
    };
  }
  return neutralColorsList;
};

// Create theme variables
const generateThemeVariables = (props: any): any => {
  const theme = props?.siteSettings;
  const appBarColor = theme?.appBarColor?.value || '#FFFFFF';
  const primaryActionColor = theme?.primaryActionColor?.value || '#015FF1';
  const primaryActionHoverColor = theme?.primaryActionHoverColor?.value || '#0557D5';
  const secondaryMainColor = theme?.secondaryMainColor?.value || '#FFFFFF';
  const secondaryDarkColor = theme?.secondaryDarkColor?.value || '#ECF3FF';
  const textColor = theme?.textColor?.value || '#444444';
  const themeLightColor = theme?.themeLightColor?.value || '#DFF3F4';
  const themeColor = theme?.themeColor?.value || '#005587'; // Other theme color: Fuschia #C42EA5
  const themeColorHover = theme?.themeColorHover?.value || '#005587';
  const themeColorFilter = `brightness(0) saturate(100%) ${
    hexToCSSFilter(themeColor, config).filter
  }`; // Note that hexToCSSFilter will only work if the image color is black initially hence passing brightness(0) saturate(100%) to make image black
  const themeColorHoverFilter = `brightness(0) saturate(100%) ${
    hexToCSSFilter(themeColorHover, config).filter
  }`;
  const whiteColor = theme?.whiteColor?.value || '#FFFFFF';
  const whiteColorFilter = `brightness(0) saturate(100%) ${
    hexToCSSFilter(whiteColor, config).filter
  }`;
  const blackColor = theme?.blackColor?.value || '#000000';
  const hintTextColor = theme?.hintTextColor?.value || '#6E6E6E';
  const backgroundColorType1 = theme?.backgroundColorType1?.value || '#2B3942';
  const backgroundColorType2 = theme?.backgroundColorType2?.value || '#6F6F6F';
  const backgroundColorType3 = theme?.backgroundColorType3?.value || '#F7F8FA';
  const backgroundColorType4 = theme?.backgroundColorType4?.value || '#272727';
  const errorColor = theme?.errorColor?.value || '#E20000';
  const warningColor = theme?.warningColor?.value || '#FF9300';
  const successColor = theme?.successColor?.value || '#009D3F';
  const themeColorSecondary = theme?.themeColorSecondary?.value || '#00beb1';
  const surveyErrorTextColor = theme?.surveyErrorTextColor?.value || '#621B16';
  const surveyErrorBackgroundColor = theme?.surveyErrorBackgroundColor?.value || '#FCE6E6';
  const surveyErrorIconColor = theme?.surveyErrorIconColor?.value || '#F44336';
  const tealColor = theme?.teal?.value || '#077D86';
  const violetColor = '#250056';
  const lightBlueColor = '#0076ae';

  return {
    palette: {
      primary: {
        main: primaryActionColor,
        dark: primaryActionHoverColor,
      },
      secondary: {
        main: secondaryMainColor,
        dark: secondaryDarkColor,
      },
      text: {
        primary: `${textColor} !important`,
      },
      white: {
        main: whiteColor,
      },
      black: {
        main: blackColor,
      },
      hintText: {
        main: hintTextColor,
      },
      backgroundType1: {
        main: backgroundColorType1,
      },
      backgroundType2: {
        main: backgroundColorType2,
      },
      backgroundColorType3: {
        main: backgroundColorType3,
      },
      backgroundType4: {
        main: backgroundColorType4,
      },
      error: {
        main: errorColor,
      },
      surveyErrorTextColor: {
        main: surveyErrorTextColor,
      },
      surveyErrorBackgroundColor: {
        main: surveyErrorBackgroundColor,
      },
      surveyErrorIconColor: {
        main: surveyErrorIconColor,
      },
      warning: {
        main: warningColor,
      },
      info: {
        main: warningColor,
      },
      success: {
        main: successColor,
      },
      appBarColor: {
        main: appBarColor,
      },
      themeColor: {
        main: themeColor,
      },
      themeLightColor: {
        main: themeLightColor,
      },
      themeColorSecondary: {
        main: themeColorSecondary,
      },
      themeColorFilter: {
        main: themeColorFilter,
        dark: themeColorHoverFilter,
      },
      whiteColorFilter: {
        main: whiteColorFilter,
      },
      teal: {
        main: tealColor,
      },
      primaryOne: {
        dark: violetColor,
        light: lightBlueColor,
      },
      ...generateAllNeutralColor(),
    },
    typography: {
      fontFamily: `"Proxima Nova", sans-serif`,
      fontWeight: 400,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 431,
        md: 768,
        lg: 960,
        xl: 1366,
      },
    },
    components: {
      // Name of the component ⚛️
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
    },
    siteName: `${props?.site?.name}`,
  };
};

// Handle responsive typography
const handleResponsiveTypography = (theme: Theme): Theme => {
  theme.typography.h1 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 700,
    fontSize: pxToRem(48),
    lineHeight: pxToRem(50),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(40),
      lineHeight: pxToRem(48),
    },
  };
  theme.typography.h2 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 700,
    fontSize: pxToRem(40),
    lineHeight: pxToRem(48),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(30),
    },
  };
  theme.typography.h3 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 400,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(40),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(32),
    },
  };
  theme.typography.h4 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 400,
    fontSize: pxToRem(24),
    lineHeight: pxToRem(32),
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(18),
    },
  };
  theme.typography.h5 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 400,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(30),
    [theme.breakpoints.down('md')]: {
      fontFamily: `"Proxima Nova", sans-serif`,
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  };
  theme.typography.h6 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20),
  };
  theme.typography.title = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 600,
    fontSize: pxToRem(32),
    lineHeight: pxToRem(32),
    [theme.breakpoints.up('md')]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(32),
      fontWeight: 500,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(24),
      lineHeight: pxToRem(32),
      fontWeight: 500,
    },
    [theme.breakpoints.down(500)]: {
      fontSize: pxToRem(20),
      lineHeight: pxToRem(32),
      fontWeight: 500,
    },
  };
  theme.typography.subtitle1 = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 400,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(32),
    [theme.breakpoints.down('lg')]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  };
  theme.typography.body = {
    fontFamily: `"Proxima Nova Regular", sans-serif`,
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  };
  theme.typography.btnText = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 600,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
  };
  theme.typography.successCardTitle = {
    fontFamily: `"Proxima Nova", sans-serif`,
    fontWeight: 600,
    fontSize: pxToRem(18),
    lineHeight: 'normal',
  };
  theme.typography.accordionTitle = {
    fontFamily: `Proxima Nova SemiBold`,
    fontWeight: 400,
    fontSize: pxToRem(22),
    lineHeight: pxToRem(30),
    [theme.breakpoints.down('md')]: {
      fontFamily: `"Proxima Nova", sans-serif`,
      fontWeight: 600,
      fontSize: pxToRem(16),
      lineHeight: pxToRem(24),
    },
  };

  return theme;
};

// Get theme
// Temporarily disable non-any type check to fix eslint warning.
// Will enable it once we get finalized Layoutservice interface
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getTheme(props: any): Theme {
  const theme = createTheme(generateThemeVariables(props));
  return handleResponsiveTypography(theme);
}
