class LogHttpClient {
  protected readonly apiUrl: string;
  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async postLogEvents(payload: unknown): Promise<any> {
    try {
      const response = await fetch(this.apiUrl, {
        body: JSON.stringify(payload),
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          Authorization: `Splunk ${process.env.NEXT_PUBLIC_SPLUNK_TOKEN}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (_e) {}
  }
}

export default LogHttpClient;
