/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthenticationState = {
  isAuthenticated: boolean;
  loading: boolean;
};

const initialState: AuthenticationState = {
  isAuthenticated: false,
  loading: true,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthentication: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setAuthentication, setLoading } = authenticationSlice.actions;

export default authenticationSlice.reducer;
