import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
  // data contains the cdp profile response for the user email
  // TODO: del initial data
  profileData: {
    // basicDetails: {
    //   gender: 'Female',
    //   dob: '2005-01-23T09:36:59.825Z',
    //   zipCode: '02215',
    // },
    // conditions: [{ conditionValue: 'asthma' }],
  },
  selectedCOI: [],
  selectedCommunities: [],
  isEmailLinkExpired: false,
  // Profile Modal
  isMyProfileModalOpen: false,
};

const crProfileSlice = createSlice({
  name: 'crProfileSlice',
  initialState,
  reducers: {
    setProfileData: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        profileData: action.payload,
      };
    },
    updateSelectedCOI: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedCOI: action.payload,
      };
    },
    updateSelectedCommunities: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        selectedCommunities: action.payload,
      };
    },
    updateIsEmailLinkExpired: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        isEmailLinkExpired: action.payload,
      };
    },
    updateMyProfileModal: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        isMyProfileModalOpen: action.payload,
      };
    },
    resetProfileData: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setProfileData,
  updateSelectedCOI,
  updateSelectedCommunities,
  updateIsEmailLinkExpired,
  updateMyProfileModal,
  resetProfileData,
} = crProfileSlice.actions;
export default crProfileSlice.reducer;
