import { createSlice } from '@reduxjs/toolkit';
import { hasCookie } from 'cookies-next';

const isAcceptCookiePresent = (): boolean => hasCookie('AcceptCookie');

export const initialState: any = {
  isShowCookieBanner: false,
};

const cookieBannerSlice = createSlice({
  name: 'cookieBanner',
  initialState,
  reducers: {
    hideCookieBanner: (state) => ({
      ...state,
      isShowCookieBanner: false,
    }),
    setIsShowCookieBanner: (state) => ({
      ...state,
      isShowCookieBanner: !isAcceptCookiePresent(),
    }),
  },
});

export const { hideCookieBanner, setIsShowCookieBanner } = cookieBannerSlice.actions;
export default cookieBannerSlice.reducer;
