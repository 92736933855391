import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const USER_TYPE = {
  PATIENT: 'patient',
  DELEGATE: 'delegate',
};

export interface AutoCompleteLocationInfo {
  [key: string]: {
    lat: number | undefined | null;
    lng: number | undefined | null;
    address: string;
    city: string;
    state: string;
    zipcode: string;
  };
}
const initialState: any = {
  stepNumber: 0,
  conditionOfIntrestList: [],
  stateList: [],
  timeZones: [],
  contactInfo: {},
  contactInfoUrl: '',
  stateZipcodeInfo: {},
  prepopulateConfig: {},
  sitecoreFieldsForContactInfo: {
    caregiver: {
      CaregiverZipCode: false,
      CaregiverCity: false,
      CaregiverState: false,
      ParticipantZipcode: false,
      ParticipantCity: false,
      ParticipantState: false,
    },
    minor: {
      ParentState: false,
      ParentCity: false,
      ZipCode: false,
      ParticipantState: false,
      ParticipantZipcode: false,
    },
    adult: {
      City: false,
      State: false,
      Zipcode: false,
    },
  },
  autoCompleteLocationInfo: {
    [USER_TYPE.PATIENT]: {
      lat: null,
      lng: null,
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
    [USER_TYPE.DELEGATE]: {
      lat: null,
      lng: null,
      address: '',
      city: '',
      state: '',
      zipcode: '',
    },
  },
  consents: [],
};

const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    goToStep: (state = initialState, action: PayloadAction<number>) => ({
      ...state,
      stepNumber: action.payload,
    }),
    updateContactInfoUrl: (state = initialState, action: PayloadAction<string>) => ({
      ...state,
      contactInfoUrl: action.payload,
    }),
    updateStateList: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      stateList: action.payload,
    }),
    updateConditionOfIntrestList: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      conditionOfIntrestList: action.payload,
    }),
    updateTimeZones: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      timeZones: action.payload,
    }),
    updateFieldIsPresentOrNotFromSitecore: (state = initialState, action: PayloadAction<any>) => ({
      ...state,
      sitecoreFieldsForContactInfo: { ...state.sitecoreFieldsForContactInfo, ...action.payload },
    }),
    updateContactInfo: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        contactInfo: { ...state.contactInfo, ...action.payload },
      };
    },
    updateStateZipCodeDetails: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        stateZipcodeInfo: { ...state.stateZipcodeInfo, ...action.payload },
      };
    },
    updatePrepopulateConfig: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        prepopulateConfig: { ...state.prepopulateConfig, ...action.payload },
      };
    },
    updateAutoCompleteLocationInfo: (
      state = initialState,
      action: PayloadAction<AutoCompleteLocationInfo>
    ) => {
      return {
        ...state,
        autoCompleteLocationInfo: { ...state.autoCompleteLocationInfo, ...action.payload },
      };
    },
    updateContactInfoConsents: (state = initialState, action: PayloadAction<any>) => {
      return {
        ...state,
        consents: [...action.payload],
      };
    },
    resetContactInfo: (state = initialState) => {
      return {
        ...state,
        contactInfo: {},
      };
    },
  },
});

export const {
  goToStep,
  updateStateList,
  updateTimeZones,
  updateContactInfo,
  updateStateZipCodeDetails,
  updatePrepopulateConfig,
  updateAutoCompleteLocationInfo,
  updateContactInfoUrl,
  updateFieldIsPresentOrNotFromSitecore,
  updateContactInfoConsents,
  updateConditionOfIntrestList,
  resetContactInfo,
} = contactInfoSlice.actions;
export default contactInfoSlice.reducer;
