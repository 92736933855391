import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  queryParamList: {},
};

const crQueryParamSlice = createSlice({
  name: 'crQueryParam',
  initialState,
  reducers: {
    updateCRQueryParamList: (state = initialState, action) => {
      const stateData = {
        ...state,
        queryParamList: action.payload,
      };
      return stateData;
    },
  },
});

export const { updateCRQueryParamList } = crQueryParamSlice.actions;
export default crQueryParamSlice.reducer;
